import iconOne from '../../assets/service/1.png';
import iconTwo from '../../assets/service/2.png';
import iconThree from '../../assets/service/3.png';
import iconFour from '../../assets/service/4.png';

const ServicesData = [
    {
        'icon': iconOne,
        'title': 'HÀM GIẢ',
        'description': 'Chúng tôi cung cấp hàm giả chất lượng cao, mang lại sự thoải mái và thẩm mỹ tối ưu.',
    },
    {
        'icon': iconTwo,
        'title': 'CẤY GHÉP IMPLANT',
        'description': 'Phục hồi nụ cười với công nghệ cấy ghép Implant hiện đại, bền chắc và tự nhiên như răng thật',
    },
    {
        'icon': iconThree,
        'title': 'TẨY TRẮNG RĂNG',
        'description': 'Dịch vụ tẩy trắng răng hiện đại, mang lại hàm răng trắng sáng tự nhiên và tự tin rạng ngời',
    },
    {
        'icon': iconFour,
        'title': 'ĐIỀU TRỊ TỦY RĂNG',
        'description': 'Điều trị tủy răng chuyên sâu, loại bỏ cơn đau hiệu quả và khôi phục sức khỏe răng miệng toàn diện',
    }
]

export default ServicesData;