import React, { useState } from 'react';
import './ContactForm.scss';
import icon from '../../assets/banner/icons/Calling.png';

const ContactForm = () => {
    const [selectedDate, setSelectedDate] = useState('');
    const [timeSlots, setTimeSlots] = useState([]);

    // Function to generate time slots dynamically based on the selected date
    const generateTimeSlots = (date) => {
        const slots = [];
        const startOfDay = new Date(date);
        startOfDay.setHours(7, 0, 0); // Start at 7:00 AM
        const endOfDay = new Date(date);
        endOfDay.setHours(22, 0, 0); // End at 10:00 PM

        while (startOfDay <= endOfDay) {
            slots.push(
                startOfDay.toLocaleTimeString('vi-VN', {
                    hour: '2-digit',
                    minute: '2-digit',
                })
            );
            startOfDay.setMinutes(startOfDay.getMinutes() + 30); // Increment by 30 minutes
        }

        setTimeSlots(slots);
    };

    // Handle date selection and generate time slots
    const handleDateChange = (event) => {
        const date = event.target.value;
        setSelectedDate(date);
        generateTimeSlots(date);
    };

    // Get today's date in YYYY-MM-DD format for the date input's min attribute
    const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    return (
        <form>
            <div className="row g-3">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>HỌ VÀ TÊN</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your name..."
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>SỐ ĐIỆN THOẠI</label>
                        <input
                            type="tel"
                            className="form-control"
                            placeholder="Enter phone number..."
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>DỊCH VỤ</label>
                        <select className="form-control">
                            <option>TỔNG QUÁT</option>
                            <option>NHỔ RĂNG THƯỜNG</option>
                            <option>NHỔ RĂNG KHÔN</option>
                            <option>ĐIỀU TRỊ TỦY RĂNG</option>
                            <option>TẨY TRẮNG RĂNG</option>
                            <option>HÀM GIẢ</option>
                            <option>RĂNG SỨ THẨM MỸ</option>
                            <option>CẤY GHÉP IMPLANT</option>
                            <option>NIỀNG RĂNG MẮC CÀI</option>
                            <option>NIỀNG KHAY TRONG SUỐT</option>
                        </select>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group">
                        <label>NGÀY</label>
                        <input
                            type="date"
                            className="form-control"
                            min={getTodayDate()} // Restrict past dates
                            onChange={handleDateChange}
                        />
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group">
                        <label>KHUNG GIỜ</label>
                        <select className="form-control">
                            <option value="">Chọn giờ</option>
                            {timeSlots.map((slot, index) => (
                                <option key={index} value={`${selectedDate} ${slot}`}>
                                    {`${selectedDate} - ${slot}`}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <label>LỜI NHẮN</label>
                        <textarea
                            className="form-control"
                            placeholder="Enter your messages..."
                            rows="3"
                        ></textarea>
                    </div>
                </div>
                <div className="col-lg-6">
                    <button type="submit" className="btn appointment-btn w-100">
                        ĐẶT LỊCH HẸN
                    </button>
                </div>
                <div className="col-lg-6" data-label-id="0">
                    <div className="appointment-call">
                        <div className="icon">
                            <img src={icon} alt="icon" />
                        </div>
                        <div className="call-text">
                            <p>NHA KHOA YẾN HƯƠNG</p>
                            <h6>0983 880 836</h6>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;
