import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopWithOffset = ({ offset = 100 }) => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // Nếu có hash trong URL (ví dụ: #faq)
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    } else {
      // Nếu không có hash, cuộn lên đầu trang
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [pathname, hash, offset]);

  return null;
};

export default ScrollToTopWithOffset;
