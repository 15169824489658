import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import priorityImg from '../../assets/about/priority.jpg';
import './Priority.scss';

const Priority = () => {
    return (
        <section className='priority-section emergency-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="priority-img">
                            <img src={priorityImg} alt="Emergency" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="priority-text">
                            <SectionTitle 
                                subTitle="ƯU TIÊN CỦA CHÚNG TÔI" 
                                title="KHÁCH HÀNG LÀ SỰ ƯU TIÊN HÀNG ĐẦU CỦA CHÚNG TÔI"
                                description="Chúng tôi cam kết mang đến dịch vụ nha khoa chất lượng cao, luôn lắng nghe và đáp ứng tốt nhất mọi nhu cầu của khách hàng. Sự hài lòng của bạn là động lực để chúng tôi không ngừng phát triển"
                            />

                            <div className="theme-btn">
                                <Link to='/contact'>ĐẶT LỊCH HẸN</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Priority;