import iconOne from '../../assets/features/1.png';
import iconTwo from '../../assets/features/2.png';
import iconThree from '../../assets/features/3.png';
import iconFour from '../../assets/features/4.png';

const featuresData = [
    {
        'icon': iconOne,
        'title': 'AN TOÀN',
        'description': 'Chúng tôi luôn đặt an toàn của bạn lên hàng đầu với các quy trình kiểm tra sức khỏe kỹ lưỡng và dịch vụ đạt tiêu chuẩn của bộ y tế'
    },
    {
        'icon': iconTwo,
        'title': 'TẬN TÂM CHĂM SÓC',
        'description': 'Đội ngũ chuyên gia của chúng tôi luôn sẵn sàng lắng nghe và mang đến giải pháp phù hợp nhất cho từng khách hàng'
    },
    {
        'icon': iconFour,
        'title': 'DỊCH VỤ NHA KHOA TOÀN DIỆN',
        'description': 'Phòng khám cung cấp đa dạng dịch vụ nha khoa, từ thăm khám định kỳ đến chăm sóc chuyên sâu, đáp ứng mọi nhu cầu của bạn'
    },
    {
        'icon': iconThree,
        'title': 'KHÔNG GIAN THƯ GIÃN',
        'description': 'Phòng khám được thiết kế hiện đại, thoải mái, giúp bạn thư giãn trong suốt quá trình chăm sóc răng miệng'
    }
]

export default featuresData;