import React from 'react';
import './Expert.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import { BsFillCheckCircleFill } from "react-icons/bs";
import expertImg from '../../assets/expert.jpg';

const Expert = () => {
    return (
        <section className='expert-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="expert-text">
                            <SectionTitle 
                                subTitle="CHUYÊN GIA TRONG LĨNH VỰC NHA KHOA"
                                title="DỊCH VỤ NHA KHOA CHẤT LƯỢNG CAO TỪ CÁC CHUYÊN GIA"
                                description="Nụ cười của bạn là ưu tiên hàng đầu của chúng tôi, với các dịch vụ nha khoa hiện đại và chăm sóc tận tình"
                            />

                            <ul>
                                <li>
                                    <BsFillCheckCircleFill />
                                    Dịch vụ nha khoa hiện đại đạt chuẩn của bộ y tế
                                </li>
                                <li>
                                    <BsFillCheckCircleFill />
                                    Ưu đãi hấp dẫn cho tất cả các dịch vụ nha khoa
                                </li>
                                <li>
                                    <BsFillCheckCircleFill />
                                    Đội ngũ bác sĩ nha khoa hàng đầu từ Bệnh Viện Đa Khoa Huyện Thủy Nguyên, Hải Phòng
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="expert-img">
                            <img src={expertImg} alt="expert" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Expert;