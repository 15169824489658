import React from 'react';
import { Link } from 'react-router-dom';
import './ServiceBanner.scss';
import serviceBanner from '../../assets/servicePage/1.jpg';

const ServiceBanner = () => {
    return (
        <section className='service-banner-section section-common section-bg'>
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <div className="service-banner-text" data-aos="fade-up" data-aos-duration="2000">
                                    <h2>DỊCH VỤ NHA KHOA & THẨM MỸ</h2>
                                    <p>Tại Nha Khoa Yến Hương, chúng tôi cung cấp các dịch vụ nha khoa toàn diện và thẩm mỹ hiện đại, từ chăm sóc sức khỏe răng miệng đến cải thiện nụ cười của bạn. Với đội ngũ bác sĩ tận tâm và công nghệ tiên tiến, chúng tôi cam kết mang lại kết quả hoàn hảo và sự hài lòng tuyệt đối cho khách hàng</p>
                                    <div className="theme-btn">
                                        <Link to="/contact">ĐẶT LỊCH HẸN</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-6">
                                <div className="service-banner-img" data-aos="fade-up" data-aos-duration="2000">
                                    <img src={serviceBanner} alt="service" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServiceBanner;