import React from "react";
import "./Emergency.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import EmergencyImg from "../../assets/emergency.jpg";
import { Link } from "react-router-dom";

const Emergency = () => {
  return (
    <section
      className="emergency-section"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6">
            <div className="emergency-img">
              <img src={EmergencyImg} alt="Emergency" />
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="emergency-text">
              <SectionTitle
                subTitle="NHA KHOA YẾN HƯƠNG"
                title="CHĂM SÓC NHẸ NHÀNG TỪ ĐỘI NGŨ CHUYÊN GIA"
                description="Dịch vụ nha khoa chuyên nghiệp, nhẹ nhàng, được thực hiện bởi đội ngũ chuyên gia giàu kinh nghiệm, mang đến nụ cười khỏe đẹp và sự hài lòng cao nhất"
              />

              <div className="theme-btn">
                <Link to="/contact">ĐẶT LỊCH HẸN</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Emergency;
