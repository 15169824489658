import React from 'react';
import { Link } from 'react-router-dom';
import './AboutBanner.scss';
import bannerOne from '../../assets/about/banner/banner_1.jpg'
import bannerTwo from '../../assets/about/banner/banner_2.jpg'
import pattern from '../../assets/banner/pattern.png'

const AboutBanner = () => {
    return (
        <section className='about-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="about-banner-text">
                                    <h2>VỀ CHÚNG TÔI</h2>
                                    <p>Chúng tôi mong muốn bạn luôn tự hào về sức khỏe răng miệng của mình, không chỉ trong những lần thăm khám mà cả trong từng khoảnh khắc bạn thưởng thức món ăn, cười nói, kể chuyện hay trao yêu thương</p>

                                    <div className="theme-btn">
                                        <Link to='/contact'>LIÊN HỆ NGAY</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="about-banner-img">
                                    <img src={bannerOne} alt="about banner"/>
                                    <img src={bannerTwo} alt="about banner two"/>
                                    <img className='pattern' src={pattern} alt="about banner two"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutBanner;