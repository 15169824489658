import React from 'react';
import './Faq.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';

const Faq = () => {
    return (
        <section id="faq" className='faq-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <SectionTitle
                    subTitle="FAQ"
                    title="CÂU HỎI THƯỜNG GẶP"
                />

                <div className="accordian-area">
                    <div className="accordion" id="accordionExample">
                        {/* Câu hỏi 1 */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Bao lâu nên đi khám răng định kỳ?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Theo khuyến cáo, nên kiểm tra răng miệng mỗi 6 tháng để kịp thời làm sạch và phát hiện các vấn đề sức khỏe răng miệng.
                                </div>
                            </div>
                        </div>

                        {/* Câu hỏi 2 */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Nhổ răng có đau không và cần bao lâu để phục hồi?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Việc nhổ răng được thực hiện nhanh chóng, thường chỉ từ 20-30 phút. Bệnh nhân sẽ không cảm thấy đau nhờ thuốc gây tê, và phục hồi nhanh với sự hỗ trợ của bác sĩ.
                                </div>
                            </div>
                        </div>

                        {/* Câu hỏi 3 */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Niềng răng có cần nhổ răng không?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Việc nhổ răng khi niềng tùy thuộc vào từng trường hợp cụ thể. Thông thường, bác sĩ sẽ chỉ định nhổ răng số 4 hoặc các răng khác để tạo không gian phù hợp cho việc điều chỉnh. Bạn cần đến cơ sở nha khoa để được tư vấn chi tiết và chính xác.
                                </div>
                            </div>
                        </div>

                        {/* Câu hỏi 4 */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Cấy ghép Implant có đau không và kéo dài bao lâu?
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Quá trình cấy ghép Implant không gây đau nhờ thuốc tê. Thời gian cấy ghép mỗi trụ thường chỉ khoảng 15 phút.
                                </div>
                            </div>
                        </div>

                        {/* Câu hỏi 5 */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Sau bao lâu có thể lắp răng trên Implant?
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Nếu xương hàm tốt, răng có thể được lắp ngay sau cấy ghép. Trong trường hợp cần ghép xương, thời gian chờ khoảng 6-8 tháng.
                                </div>
                            </div>
                        </div>

                        {/* Câu hỏi 6 */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSix">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    Niềng răng mất bao lâu?
                                </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Thời gian niềng răng thường dao động từ 18-24 tháng, tùy thuộc vào tình trạng răng miệng của từng người.
                                </div>
                            </div>
                        </div>

                        {/* Câu hỏi 7 */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSeven">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    Niềng răng nên có chế độ ăn như thế nào?
                                </button>
                            </h2>
                            <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Để đạt hiệu quả chỉnh nha tối ưu, bạn nên chú ý hơn trong chế độ ăn. Nên chọn các thực phẩm mềm, chín kỹ như các sản phẩm từ sữa, trứng, thịt mềm, hải sản, và rau củ nấu chín. Chúng không chỉ giúp bảo vệ răng miệng mà còn hỗ trợ sức khỏe tổng thể.
                                </div>
                            </div>
                        </div>

                        {/* Câu hỏi 8 */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingEight">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    Cách vệ sinh răng miệng đúng cách sau khi niềng răng?
                                </button>
                            </h2>
                            <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Việc vệ sinh răng miệng kỹ lưỡng sau khi niềng là điều cần thiết. Hãy sử dụng bàn chải lông mềm kết hợp kem đánh răng có chứa fluoride. Dùng chỉ nha khoa để làm sạch các mảng bám trong kẽ răng thay vì sử dụng tăm xỉa răng.
                                </div>
                            </div>
                        </div>

                        {/* Câu hỏi 9 */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingNine">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    Chụp X-quang răng có nguy hiểm không?
                                </button>
                            </h2>
                            <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Lượng tia từ máy chụp X-quang hiện đại rất thấp và an toàn, đảm bảo không ảnh hưởng đến sức khỏe.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Faq;
