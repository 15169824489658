// src/pages/SingleBlog/SingleBlog.jsx

import React from 'react';
import './SingleBlog.scss';
import Navbar from '../../components/Navbar/Navbar';
import { Link, useParams } from 'react-router-dom';
import BlogData from '../../sections/Blogs/BlogData';
import Footer from '../../sections/Footer/Footer';
import ReactMarkdown from 'react-markdown';

const SingleBlog = () => {
    const { url } = useParams();
    const blog = BlogData.find(blog => blog.url === url);

    if (!blog) {
        return (
            <div className="container">
                <h2>Blog Not Found</h2>
                <p>Sorry, the blog you are looking for does not exist.</p>
            </div>
        );
    }

    const { title, /* img, */ category, content } = blog;

    return (
        <>
            <Navbar />
            <section className='section-bg pb-70 single-blog'>
                <div className="container">
                    <div className="row">
                        {/* Main Blog Content */}
                        <div className="col-lg-9">
                            <main className="single-blog-area">
                                <p className="single-blog-category">{category}</p>
                                <h2 className="single-blog-title">{title}</h2>
                                {/* <img className='single-blog-banner' src={img} alt="blog banner" /> */}
                                <div className="single-blog-text">
                                    {content.map((section, index) => {
                                        let contentElement;

                                        switch (section.type) {
                                            case 'heading':
                                                contentElement = (
                                                    <h3 key={index} className="single-blog-heading">
                                                        {section.value}
                                                    </h3>
                                                );
                                                break;
                                            case 'subheading':
                                                contentElement = (
                                                    <h4 key={index} className="single-blog-subheading">
                                                        {section.value}
                                                    </h4>
                                                );
                                                break;
                                            case 'paragraph':
                                                contentElement = (
                                                    <div key={index} className="single-blog-paragraph">
                                                        <ReactMarkdown>{section.value}</ReactMarkdown>
                                                    </div>
                                                );
                                                break;
                                            case 'list':
                                                contentElement = (
                                                    <ul key={index} className="single-blog-list">
                                                        {section.value.map((item, i) => (
                                                            <li key={i}>
                                                                <ReactMarkdown>{item}</ReactMarkdown>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                );
                                                break;
                                            case 'quote':
                                                contentElement = (
                                                    <blockquote key={index} className="single-blog-quote">
                                                        <ReactMarkdown>{section.value}</ReactMarkdown>
                                                    </blockquote>
                                                );
                                                break;
                                            default:
                                                contentElement = null;
                                        }

                                        return (
                                            <div key={index} className="single-blog-section">
                                                {contentElement}
                                                {/* Hiển thị hình ảnh nếu có */}
                                                {section.image && (
                                                    <img
                                                        src={section.image}
                                                        alt={`section-${index}`}
                                                        className="single-blog-image"
                                                    />
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </main>
                        </div>
                        {/* Sidebar */}
                        <div className="col-lg-3">
                            <div className="blog-sidebar">
                                {/* Subscribe Section */}
                                <div className="sidebar-subscribe mb-5">
                                    <h3 className="sidebar-heading">Nhận Thông Tin Mới Nhất</h3>
                                    <form>
                                        <div className="form-group">
                                            <input type="email" className='form-control' placeholder='Đăng ký email' />
                                            <button className='btn btn-primary mt-1 w-100'>Đăng Ký</button>
                                        </div>
                                    </form>
                                </div>
                                {/* Categories Section */}
                                <div className="sidebar-category mb-3">
                                    <h3 className="sidebar-heading">Chuyên Mục</h3>
                                    <ul>
                                        <li><Link to="/">Công Nghệ</Link></li>
                                        <li><Link to="/">Chăm Sóc Răng</Link></li>
                                        <li><Link to="/">Sức Khỏe Răng Miệng</Link></li>
                                        <li><Link to="/">Thẩm Mỹ Nha Khoa</Link></li>
                                        <li><Link to="/">Tin Tức</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default SingleBlog;
