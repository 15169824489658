import React from 'react';
import './Testimonial.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import imgOne from '../../assets/testimonial/1.jpg';
import imgTwo from '../../assets/testimonial/2.jpg';
import imgThree from '../../assets/testimonial/3.jpg';
import imgFour from '../../assets/testimonial/4.jpg';
import imgFive from '../../assets/testimonial/5.jpg';
import imgSix from '../../assets/testimonial/6.jpg';
import TestimoniCard from '../../components/TestimoniCard/TestimoniCard';
// { AiFillStar } from "react-icons/ai";
import Slider from "react-slick";

const Testimonial = () => {

    const testimonails = [
        {
            'img': imgOne,
            'name': 'Anh Quốc Thắng',
            'description' : 'Tôi rất hài lòng, làm răng không đau, giá cả hợp lý mà dịch vụ thì chất lượng. Chắc chắn sẽ quay lại để chăm sóc răng miệng lâu dài.',
            //'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        },
        {
            'img': imgTwo,
            'name': 'Anh Phú Hưng',
            'description' : 'Phòng khám sạch sẽ, nhân viên thân thiện, làm răng mà như đi spa vậy. Mình cực kỳ hài lòng, răng đẹp hơn hẳn, cảm ơn đội ngũ nha khoa!',
            //'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        },
        {
            'img': imgThree,
            'name': 'Chị Ngọc Giàu',
            'description' : 'Răng mình bây giờ xinh quá trời! Ai cũng khen răng đẹp, mà mình thì vui lắm. Cảm ơn các bác sĩ ở đây, vừa giỏi vừa dễ thương!',
            //'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,]
        },
        {
            'img': imgFour,
            'name': 'Chị Thu Hà',
            'description' : 'Lần đầu đến mà ưng ý quá trời! Bác sĩ vừa mát tay vừa dễ thương, giải thích cặn kẽ từng chút một. Ai đang tìm chỗ làm răng thì ghé ngay nhé!',
            //'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        },
        {
            'img': imgFive,
            'name': 'Cô Hoa',
            'description' : 'Tôi làm răng ở đây, bác sĩ nhẹ nhàng lắm, không đau mà răng làm xong nhìn cũng đẹp nữa. Dịch vụ tốt, rất hài lòng.',
            //'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        },
        {
            'img': imgSix,
            'name': 'Chú Tâm',
            'description' : 'Phòng khám làm việc chuyên nghiệp, bác sĩ giỏi và tư vấn rõ ràng. Tôi làm xong thấy răng đẹp hẳn ra, ăn uống thoải mái hơn nhiều. Cảm ơn nha khoa!',
            //'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        }
    ]

    // slider
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };

    return (
        <section className='testimonail-section section-bg section-common pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <SectionTitle 
                        subTitle="ĐÁNH GIÁ"
                        title="CẢM NHẬN TỪ KHÁCH HÀNG"
                        />
                    </div>
                    <div className="col-lg-6">
                        <p className="pt-5">Hãy lắng nghe cảm nhận từ những khách hàng đã trải nghiệm dịch vụ của chúng tôi</p>
                    </div>
                </div>

                <Slider {...settings} className="testimoni-slider">
                    {
                        testimonails.map(testimonail => <TestimoniCard testimonail={testimonail} />)
                    }
                </Slider>
               
            </div>
        </section>
    );
};

export default Testimonial;