import React from 'react';
import './Symptoms.scss';
import IconList from '../../components/IconList/IconList';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import iconOne from '../../assets/symptoms/1.png';
import iconTwo from '../../assets/symptoms/2.png';
import iconThree from '../../assets/symptoms/3.png';
import iconFour from '../../assets/symptoms/4.png';
import iconFive from '../../assets/symptoms/5.png';
import iconSix from '../../assets/symptoms/6.png'
import iconSeven from '../../assets/symptoms/7.png'
import iconEight from '../../assets/symptoms/8.png'
import iconNine from '../../assets/symptoms/9.png'
import iconTen from '../../assets/symptoms/10.png'
const Symptoms = () => {
    const symptomsData = [
        {
            'icon' : iconOne,
            'title' : 'KHÁM TỔNG QUÁT',
            'description': 'Khám tổng quát giúp phát hiện sớm các vấn đề răng miệng và bảo vệ sức khỏe toàn diện của bạn.'
        },
        {
            'icon' : iconTwo,
            'title' : 'NHỔ RĂNG THƯỜNG',
            'description': 'Dịch vụ nhổ răng an toàn, nhanh chóng, giảm thiểu tối đa cảm giác khó chịu cho khách hàng.'
        },
        {
            'icon' : iconThree,
            'title' : 'NHỔ RĂNG KHÔN',
            'description': 'Nhổ răng khôn hiệu quả, giảm đau tối ưu với đội ngũ chuyên gia giàu kinh nghiệm.'
        },
        {
            'icon' : iconFour,
            'title' : 'ĐIỀU TRỊ TỦY RĂNG',
            'description': 'Điều trị tủy răng chuyên sâu giúp loại bỏ cơn đau và bảo tồn răng thật lâu dài.'
        },
        {
            'icon' : iconFive,
            'title' : 'TẨY TRẮNG RĂNG',
            'description': 'Dịch vụ tẩy trắng răng hiện đại, mang lại hàm răng trắng sáng và nụ cười tự tin.'
        },
        {
            'icon' : iconSix,
            'title' : 'HÀM GIẢ',
            'description': 'Hàm giả chất lượng cao, đảm bảo phù hợp, thẩm mỹ, mang lại sự thoải mái cho khách hàng.'
        },
        {
            'icon' : iconSeven,
            'title' : 'RĂNG SỨ THẨM MỸ',
            'description': 'Dịch vụ răng sứ thẩm mỹ cải thiện nụ cười, tăng sự tự tin với vẻ đẹp tự nhiên.'
        },
        {
            'icon' : iconEight,
            'title' : 'CẤY GHÉP IMPLANT',
            'description': 'Cấy ghép Implant vững chắc và tự nhiên, thay thế răng mất với công nghệ hiện đại.'
        },
        {
            'icon' : iconNine,
            'title' : 'NIỀNG RĂNG MẮC CÀI',
            'description': 'Niềng răng mắc cài giúp sắp xếp lại răng lệch, mang đến nụ cười đều đẹp và tự tin.'
        },
        {
            'icon' : iconTen,
            'title' : 'NIỀNG KHAY TRONG SUỐT',
            'description': 'Niềng khay trong suốt thẩm mỹ, giúp chỉnh nha mà không ảnh hưởng đến vẻ ngoài.'
        }
    ]

    return (
        <section className='symptoms-section section-common pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <SectionTitle 
                    subTitle="DỊCH VỤ"
                    title="DỊCH VỤ NHA KHOA CAO CẤP"
                    description="Kiến Tạo Nụ Cười - Chắp Cánh Tự Tin"
                />

                <div className="row">
                    {
                        symptomsData.map(singleSymptoms => 
                            <IconList 
                                icon={singleSymptoms.icon}
                                title={singleSymptoms.title}
                                description={singleSymptoms.description}
                            />
                        )
                    }
                </div>
            </div>
        </section>
    );
};

export default Symptoms;