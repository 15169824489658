import React from 'react';
import './Features.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import IconList from '../../components/IconList/IconList';
import featuresData from './FeaturesData';

const Features = () => {
    return (
        <section className='section-bg section-common features-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <SectionTitle subTitle="ƯU ĐIỂM DỊCH VỤ" title="CHĂM SÓC CHUYÊN SÂU TỪ ĐỘI NGŨ GIÀU KINH NGHIỆM" description="Chúng tôi cam kết mang đến dịch vụ nha khoa chất lượng cao, kết hợp giữa chuyên môn vững vàng và sự tận tâm trong từng trải nghiệm của khách hàng"/>

                <div className="row align-items-center">
                    {
                        featuresData.map(singleFeature => 
                            <IconList 
                                icon={singleFeature.icon} 
                                title={singleFeature.title}
                                description={singleFeature.description}
                            />
                        )
                    }
                </div>
            </div>
        </section>
    );
};

export default Features;