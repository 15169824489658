import React from 'react';
import './Appointment.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import {AiFillHome} from "react-icons/ai";
import ContactForm from '../../components/ContactForm/ContactForm';

const Appointment = () => {

    const mapLink = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3726.8070782477853!2d106.67656501108956!3d20.92007999142145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314a7b78d7bcd2e7%3A0x498b2c150b04b4a1!2zTmhhIEtob2EgWeG6v24gSMawxqFuZw!5e0!3m2!1sen!2s!4v1732356823683!5m2!1sen!2s'

    return (
        <section className='appointment-section pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-6">
                        <div className="google-map">
                            <iframe title='map' src={mapLink}></iframe>

                            <div className="location-name">
                                <AiFillHome />
                                <p>143 Bạch Đằng, TT. Núi Đèo, Thủy Nguyên, Hải Phòng, Việt Nam</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6">
                        <div className="appointment-form-area">
                            <SectionTitle
                            subTitle="ĐẶT LỊCH HẸN"
                            title="DỊCH VỤ TẠI NHA KHOA YẾN HƯƠNG MANG LẠI SỰ THOẢI MÁI VÀ HÀI LÒNG"
                            description="Hãy đặt lịch hẹn ngay hôm nay để trải nghiệm dịch vụ chăm sóc răng miệng tận tâm và chuyên nghiệp"/>

                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Appointment;