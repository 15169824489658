// src/sections/Blogs/BlogData.js

import imgOne from '../../assets/blogs/1.png';
import imgTwo from '../../assets/blogs/2.png';
import imgThree from '../../assets/blogs/3.png';

const BlogData = [
    {
        img: imgOne,
        url: 'latest-dental-technology-advancements',
        title: 'Công Nghệ In 3D – Tương Lai Của Ngành Nha Khoa',
        category: 'CÔNG NGHỆ',
        content: [
            {
                type: 'heading',
                value: 'Giới Thiệu Về Công Nghệ In 3D Trong Nha Khoa',
                image: 'https://luxcreo.com/wp-content/uploads/2021/06/DMR_facing-Computer.png' // Hình ảnh máy in 3D trong nha khoa
            },
            {
                type: 'paragraph',
                value: 'Công nghệ in 3D đã và đang tạo ra một cuộc cách mạng trong nhiều lĩnh vực, từ sản xuất, y học đến nghệ thuật. Trong ngành nha khoa, in 3D không chỉ là một công cụ hỗ trợ mà còn là yếu tố then chốt định hình tương lai của ngành.',
                // Hình ảnh không cần thiết ở đây
            },
            {
                type: 'heading',
                value: 'Ứng Dụng Của Công Nghệ In 3D Trong Nha Khoa',
                // Hình ảnh không cần thiết
            },
            {
                type: 'paragraph',
                value: 'Trong quá khứ, việc tạo ra các mô hình răng hay thiết bị hỗ trợ như niềng răng, mão răng thường mất nhiều thời gian và công sức. Với công nghệ in 3D, quá trình này được rút ngắn đáng kể.',
                image: 'https://www.alexanderdanielsglobal.com/wp-content/uploads/article_dental4.jpg' // Hình ảnh quá trình quét kỹ thuật số hàm răng
            },
            {
                type: 'subheading',
                value: 'Chế Tạo Mão Răng Và Cầu Răng',
                image: 'https://t3.ftcdn.net/jpg/09/64/38/34/360_F_964383418_k0SZBNuHDlpl7irHEWN3EIUgNVZ5xaJC.jpg' // Hình ảnh mão răng được in 3D
            },
            {
                type: 'paragraph',
                value: 'In 3D cho phép tạo ra mão răng và cầu răng với độ chính xác cao, phù hợp hoàn hảo với cấu trúc hàm của từng bệnh nhân.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'subheading',
                value: 'Sản Xuất Khay Chỉnh Nha',
                image: 'https://amfg.ai/wp-content/uploads/2019/08/clear-aligners-e1571293833846.jpg' // Hình ảnh khay chỉnh nha trong suốt
            },
            {
                type: 'paragraph',
                value: 'Các khay chỉnh nha trong suốt có thể được sản xuất nhanh chóng thông qua công nghệ in 3D, giúp bệnh nhân không phải chờ đợi lâu.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'subheading',
                value: 'Tạo Mô Hình Phẫu Thuật',
                // Hình ảnh không cần thiết
            },
            {
                type: 'paragraph',
                value: 'Trong các ca phẫu thuật phức tạp, việc có một mô hình 3D của hàm và răng giúp nha sĩ lập kế hoạch chính xác.',
                image: 'https://mlsw.com/wp-content/uploads/2023/09/Image2-1280x720.png' // Hình ảnh mô hình hàm răng 3D
            },
            {
                type: 'heading',
                value: 'Lợi Ích Nổi Bật Của Công Nghệ In 3D',
                // Hình ảnh không cần thiết
            },
            {
                type: 'list',
                value: [
                    '**Rút ngắn thời gian điều trị:** Quy trình nhanh chóng giúp tiết kiệm thời gian cho cả nha sĩ và bệnh nhân.',
                    '**Độ chính xác cao:** Công nghệ số hóa đảm bảo mọi chi tiết đều được tái hiện chính xác.',
                    '**Chi phí hợp lý:** Giảm thiểu chi phí sản xuất và vật liệu.',
                    '**Tùy chỉnh cá nhân:** Sản phẩm được thiết kế riêng cho từng bệnh nhân.'
                ],
                // Hình ảnh không cần thiết
            },
            {
                type: 'quote',
                value: '“In 3D không chỉ là công nghệ, mà là tương lai của nha khoa phục hình.”',
                // Hình ảnh không cần thiết
            },
            {
                type: 'heading',
                value: 'Thách Thức Và Triển Vọng',
                // Hình ảnh không cần thiết
            },
            {
                type: 'paragraph',
                value: 'Mặc dù có nhiều lợi ích, việc áp dụng công nghệ in 3D trong nha khoa cũng đối mặt với một số thách thức như chi phí đầu tư ban đầu cao.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'subheading',
                value: 'Đào Tạo Và Phát Triển Kỹ Năng',
                image: 'https://www.burkhartdental.com/wp-content/uploads/2019/03/HPN-3D-Printing-1024x751.png' // Hình ảnh đào tạo nha sĩ
            },
            {
                type: 'paragraph',
                value: 'Các trường đại học và trung tâm đào tạo nha khoa cần cập nhật chương trình giảng dạy, bổ sung kiến thức về công nghệ in 3D.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'subheading',
                value: 'Hợp Tác Công Nghệ',
                // Hình ảnh không cần thiết
            },
            {
                type: 'paragraph',
                value: 'Sự hợp tác giữa các công ty công nghệ và cơ sở nha khoa sẽ thúc đẩy sự phát triển và ứng dụng rộng rãi của in 3D.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'heading',
                value: 'Kết Luận',
                // Hình ảnh không cần thiết
            },
            {
                type: 'paragraph',
                value: 'Công nghệ in 3D đang và sẽ tiếp tục định hình tương lai của ngành nha khoa.',
                image: 'https://images.pexels.com/photos/3845981/pexels-photo-3845981.jpeg' // Hình ảnh nha sĩ và bệnh nhân hài lòng
            },
            {
                type: 'paragraph',
                value: '*Hãy đón nhận công nghệ in 3D để mang lại nụ cười hoàn hảo cho mọi bệnh nhân.*',
                // Hình ảnh không cần thiết
            }
        ]
    },
    {
        img: imgTwo,
        url: 'importance-of-regular-dental-checkups',
        title: 'Tầm Quan Trọng Của Việc Kiểm Tra Nha Khoa Định Kỳ',
        category: 'CHĂM SÓC',
        content: [
            {
                type: 'heading',
                value: 'Sức Khỏe Răng Miệng Và Sức Khỏe Toàn Thân',
                image: 'https://www.dufffamilydental.com/wp-content/uploads/2018/05/Depositphotos_24722895_s-2015.jpg' // Hình ảnh răng miệng khỏe mạnh
            },
            {
                type: 'paragraph',
                value: 'Răng miệng là cửa ngõ của cơ thể, nơi bắt đầu quá trình tiêu hóa và cũng là nơi vi khuẩn dễ dàng xâm nhập.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'heading',
                value: 'Phát Hiện Sớm Các Vấn Đề Về Răng Miệng',
                image: 'https://wbstraining.vn/wp-content/uploads/2023/05/tro-ly-nha-khoa-2.jpg' // Hình ảnh kiểm tra nha khoa
            },
            {
                type: 'paragraph',
                value: 'Nhiều bệnh lý răng miệng phát triển âm thầm mà không có triệu chứng rõ ràng. Kiểm tra định kỳ giúp phát hiện sớm những dấu hiệu bất thường.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'subheading',
                value: 'Lợi Ích Của Việc Phát Hiện Sớm',
                // Hình ảnh không cần thiết
            },
            {
                type: 'list',
                value: [
                    '**Tiết kiệm chi phí điều trị:** Điều trị sớm thường đơn giản và ít tốn kém hơn.',
                    '**Giảm thiểu đau đớn:** Ngăn chặn bệnh tiến triển đến giai đoạn gây đau nhức.',
                    '**Bảo tồn răng tự nhiên:** Tránh việc phải nhổ răng và thay thế bằng răng giả.'
                ],
                // Hình ảnh không cần thiết
            },
            {
                type: 'heading',
                value: 'Làm Sạch Răng Chuyên Sâu Tại Nha Khoa',
                image: 'https://myfavoritedentist.com/wp-content/uploads/2020/03/CLIENT-Woman-doing-laser-whitening-scaled-1.jpeg' // Hình ảnh làm sạch răng chuyên nghiệp
            },
            {
                type: 'paragraph',
                value: 'Việc chải răng hàng ngày chỉ làm sạch khoảng 60% bề mặt răng. Mảng bám và cao răng tích tụ ở những nơi bàn chải không thể chạm tới.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'subheading',
                value: 'Quy Trình Làm Sạch Chuyên Nghiệp',
                // Hình ảnh không cần thiết
            },
            {
                type: 'paragraph',
                value: '1. **Kiểm tra tổng quát:** Đánh giá tình trạng răng và nướu.\n2. **Loại bỏ mảng bám và cao răng:** Sử dụng dụng cụ chuyên dụng để làm sạch.\n3. **Đánh bóng răng:** Loại bỏ vết ố và làm răng sáng bóng.\n4. **Florua hóa:** Bảo vệ men răng khỏi axit và vi khuẩn.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'heading',
                value: 'Ngăn Ngừa Bệnh Lý Nghiêm Trọng',
                // Hình ảnh không cần thiết
            },
            {
                type: 'paragraph',
                value: 'Các nghiên cứu đã chứng minh mối liên hệ giữa bệnh nha chu và các bệnh lý nghiêm trọng khác như tim mạch, đột quỵ và tiểu đường.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'subheading',
                value: 'Tác Động Đến Sức Khỏe Toàn Thân',
                // Hình ảnh không cần thiết
            },
            {
                type: 'list',
                value: [
                    '**Bệnh tim mạch:** Viêm nha chu tăng nguy cơ viêm nội tâm mạc và xơ vữa động mạch.',
                    '**Tiểu đường:** Người bị tiểu đường dễ mắc bệnh nha chu và ngược lại.',
                    '**Biến chứng thai kỳ:** Viêm nha chu có thể dẫn đến sinh non và trẻ nhẹ cân.'
                ],
                // Hình ảnh không cần thiết
            },
            {
                type: 'heading',
                value: 'Lời Khuyên Từ Chuyên Gia Nha Khoa',
                image: 'https://www.stil-magazin.com/wp-content/uploads/2024/05/s2.jpg' // Hình ảnh nha sĩ tư vấn bệnh nhân
            },
            {
                type: 'paragraph',
                value: 'Việc duy trì thói quen kiểm tra nha khoa định kỳ là đầu tư cho sức khỏe lâu dài. Hãy lựa chọn một phòng khám uy tín và xây dựng mối quan hệ tốt với nha sĩ của bạn.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'subheading',
                value: 'Các Bước Hành Động Cụ Thể',
                // Hình ảnh không cần thiết
            },
            {
                type: 'list',
                value: [
                    '**Lên lịch kiểm tra mỗi 6 tháng:** Đừng bỏ lỡ các buổi hẹn định kỳ.',
                    '**Chia sẻ thông tin sức khỏe:** Thông báo cho nha sĩ về tình trạng sức khỏe tổng quát.',
                    '**Tuân thủ hướng dẫn:** Thực hiện đúng theo chỉ định và lời khuyên của nha sĩ.'
                ],
                // Hình ảnh không cần thiết
            },
            {
                type: 'heading',
                value: 'Kết Luận',
                // Hình ảnh không cần thiết
            },
            {
                type: 'paragraph',
                value: 'Sức khỏe răng miệng đóng vai trò quan trọng trong chất lượng cuộc sống. Đừng để những vấn đề nhỏ trở thành nghiêm trọng chỉ vì sự chủ quan.',
            },
            {
                type: 'paragraph',
                value: '*Sự quan tâm đến răng miệng là biểu hiện của việc yêu thương bản thân.*',
                // Hình ảnh không cần thiết
            }
        ]
    },
    {
        img: imgThree,
        url: 'comprehensive-guide-to-dental-hygiene',
        title: 'Hướng Dẫn Vệ Sinh Răng Miệng Đúng Cách Cho Mọi Độ Tuổi',
        category: 'VỆ SINH',
        content: [
            {
                type: 'heading',
                value: 'Tại Sao Vệ Sinh Răng Miệng Đúng Cách Lại Quan Trọng?',
                image: 'https://smartpediatric.com/wp-content/uploads/Young-girl-brushing-her-teeth.jpg' // Hình ảnh răng miệng khỏe mạnh
            },
            {
                type: 'paragraph',
                value: 'Vệ sinh răng miệng không chỉ giúp bạn có một nụ cười đẹp mà còn ngăn ngừa nhiều bệnh lý nguy hiểm.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'heading',
                value: 'Đánh Răng Đúng Cách',
                image: 'https://www.colgate.com.vn/content/dam/cp-sites/oral-care/oral-care-center/global/article/gscp/asia/how-to-brush-your-teeth-properly-a-quick-guide-0213.jpg' // Hình ảnh đánh răng đúng cách
            },
            {
                type: 'paragraph',
                value: 'Đánh răng là bước cơ bản nhất trong việc chăm sóc răng miệng, nhưng cần thực hiện đúng kỹ thuật để đạt hiệu quả cao.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'subheading',
                value: 'Các Bước Đánh Răng Hiệu Quả',
                // Hình ảnh không cần thiết
            },
            {
                type: 'list',
                value: [
                    '**Chọn bàn chải phù hợp:** Bàn chải lông mềm, đầu nhỏ giúp làm sạch mọi góc cạnh.',
                    '**Sử dụng kem đánh răng chứa fluoride:** Giúp bảo vệ men răng và ngăn ngừa sâu răng.',
                    '**Đặt góc chải 45 độ:** Làm sạch cả bề mặt răng và viền nướu.',
                    '**Chải theo chuyển động tròn:** Nhẹ nhàng và đều đặn trên mọi bề mặt răng.',
                    '**Đánh răng ít nhất 2 phút:** Đảm bảo làm sạch toàn bộ khoang miệng.',
                    '**Chải lưỡi:** Loại bỏ vi khuẩn và ngăn ngừa hôi miệng.'
                ],
                // Hình ảnh không cần thiết
            },
            {
                type: 'heading',
                value: 'Sử Dụng Chỉ Nha Khoa Và Nước Súc Miệng',
                // Hình ảnh không cần thiết
            },
            {
                type: 'paragraph',
                value: 'Bên cạnh đánh răng, sử dụng chỉ nha khoa và nước súc miệng giúp tăng cường hiệu quả vệ sinh.',
                image: 'https://nhakhoadongnam.com/wp-content/uploads/2020/12/chi-nha-khoa.jpeg' // Hình ảnh người sử dụng chỉ nha khoa
            },
            {
                type: 'subheading',
                value: 'Lợi Ích Của Chỉ Nha Khoa',
                // Hình ảnh không cần thiết
            },
            {
                type: 'list',
                value: [
                    '**Loại bỏ mảng bám giữa các kẽ răng:** Ngăn ngừa sâu răng và viêm nướu.',
                    '**Cải thiện sức khỏe nướu:** Giảm viêm và chảy máu nướu.',
                    '**Hơi thở thơm mát:** Loại bỏ thức ăn mắc kẹt gây mùi.'
                ],
                // Hình ảnh không cần thiết
            },
            {
                type: 'subheading',
                value: 'Sử Dụng Nước Súc Miệng',
                // Hình ảnh không cần thiết
            },
            {
                type: 'paragraph',
                value: 'Nước súc miệng chứa chất kháng khuẩn giúp tiêu diệt vi khuẩn, giảm mảng bám và mang lại hơi thở thơm mát.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'heading',
                value: 'Chế Độ Ăn Uống Và Thói Quen Lành Mạnh',
                // Hình ảnh không cần thiết
            },
            {
                type: 'paragraph',
                value: 'Chế độ ăn uống ảnh hưởng lớn đến sức khỏe răng miệng. Thực phẩm giàu đường và axit có thể gây hại cho men răng.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'subheading',
                value: 'Thực Phẩm Nên Tránh',
                // Hình ảnh không cần thiết
            },
            {
                type: 'list',
                value: [
                    '**Đồ ngọt và bánh kẹo:** Gây sâu răng do lượng đường cao.',
                    '**Nước uống có gas và nước ép trái cây công nghiệp:** Chứa axit và đường làm mòn men răng.',
                    '**Thực phẩm cứng và dính:** Dễ mắc kẹt và khó làm sạch.'
                ],
                // Hình ảnh không cần thiết
            },
            {
                type: 'subheading',
                value: 'Thực Phẩm Tốt Cho Răng Miệng',
                // Hình ảnh không cần thiết
            },
            {
                type: 'list',
                value: [
                    '**Rau củ quả tươi:** Chứa vitamin và khoáng chất, kích thích tiết nước bọt.',
                    '**Sữa và sản phẩm từ sữa:** Cung cấp canxi và phosphate cho men răng.',
                    '**Nước lọc:** Giúp rửa trôi vi khuẩn và mảng bám.'
                ],
                // Hình ảnh không cần thiết
            },
            {
                type: 'heading',
                value: 'Chăm Sóc Răng Miệng Theo Độ Tuổi',
                // Hình ảnh không cần thiết
            },
            {
                type: 'paragraph',
                value: 'Mỗi giai đoạn cuộc đời có nhu cầu chăm sóc răng miệng khác nhau.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'subheading',
                value: 'Trẻ Em',
                image: 'https://www.yourdentistryguide.com/wp-content/uploads/2017/11/kids-dentistry-min.jpg' // Hình ảnh trẻ em đánh răng
            },
            {
                type: 'paragraph',
                value: 'Hình thành thói quen vệ sinh răng miệng từ sớm rất quan trọng. Cha mẹ nên hướng dẫn và giám sát trẻ chải răng đúng cách.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'subheading',
                value: 'Thanh Thiếu Niên',
                // Hình ảnh không cần thiết
            },
            {
                type: 'paragraph',
                value: 'Giai đoạn này, nguy cơ sâu răng và viêm nướu tăng cao do thay đổi hormone và thói quen ăn uống.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'subheading',
                value: 'Người Lớn',
                // Hình ảnh không cần thiết
            },
            {
                type: 'paragraph',
                value: 'Duy trì thói quen vệ sinh răng miệng và kiểm tra định kỳ giúp ngăn ngừa các bệnh lý như nha chu, mất răng.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'subheading',
                value: 'Người Cao Tuổi',
                // Hình ảnh không cần thiết
            },
            {
                type: 'paragraph',
                value: 'Răng và nướu trở nên nhạy cảm hơn. Sử dụng bàn chải lông mềm, kem đánh răng dành cho răng nhạy cảm.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'heading',
                value: 'Kết Luận',
                image: 'https://img.freepik.com/premium-photo/perfect-kids-smile-happy-child-with-beautiful-white-milk-toothy-smile-child-dental-care_947762-6520.jpg' // Hình ảnh nụ cười khỏe mạnh
            },
            {
                type: 'paragraph',
                value: 'Vệ sinh răng miệng đúng cách là nền tảng cho sức khỏe răng miệng và sức khỏe toàn thân.',
                // Hình ảnh không cần thiết
            },
            {
                type: 'subheading',
                value: 'Hành Động Ngay Hôm Nay',
                // Hình ảnh không cần thiết
            },
            {
                type: 'list',
                value: [
                    '**Xây dựng thói quen tốt:** Đánh răng, dùng chỉ nha khoa và nước súc miệng hàng ngày.',
                    '**Giáo dục gia đình:** Hướng dẫn và khuyến khích mọi thành viên chăm sóc răng miệng.',
                    '**Thăm khám nha khoa định kỳ:** Phát hiện và điều trị sớm các vấn đề.'
                ],
                // Hình ảnh không cần thiết
            },
            {
                type: 'paragraph',
                value: '*Một nụ cười khỏe mạnh bắt đầu từ sự chăm sóc đúng cách. Hãy đầu tư thời gian và công sức để bảo vệ nụ cười của bạn và những người thân yêu.*',
                // Hình ảnh không cần thiết
            }
        ]
    }
];

export default BlogData;
