import React from "react";
import "./Navbar.scss";
import logo from "./../../assets/logo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (location.pathname === "/") {
      // If already on the homepage, scroll to the top
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      // Navigate to the homepage
      navigate("/");
    }
  };

  const navbarItems = [
    {
      name: "TRANG CHỦ",
      path: "/",
    },
    {
      name: "VỀ CHÚNG TÔI",
      path: "/about",
    },
    {
      name: "DỊCH VỤ",
      path: "/service",
    },
    {
      name: "TIN TỨC",
      path: "/blogs",
    },
    {
      name: "LIÊN HỆ",
      path: "/contact",
    },
  ];

  return (
    <div className="main-nav">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            {/* Logo */}
            <Link className="navbar-brand" to="/" onClick={handleLogoClick}>
              <img src={logo} alt="logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              {/* Navbar Links */}
              <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                {navbarItems.map((navSingle, index) => (
                  <li className="nav-item" key={index}>
                    <Link className="nav-link" to={navSingle.path}>
                      {navSingle.name}
                    </Link>
                  </li>
                ))}
              </ul>

              {/* Navbar Button */}
              <div className="theme-btn">
                <Link to="/contact">ĐẶT LỊCH HẸN</Link>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;