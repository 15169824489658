import React from "react";
import logo from "../../assets/footer_logo.png";
import "./Footer.scss";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import call from "../../assets/footer/calling.png";
import time from "../../assets/footer/time.png";
import location from "../../assets/footer/location.png";
import { FaCode } from "react-icons/fa";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  // Function to handle direct scroll to the top
  const handleHomeClick = (e) => {
    e.preventDefault();
    navigate("/");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const footerMenu = [
    {
      name: "Trang Chủ",
      link: "/",
      onClick: handleHomeClick, // Add click handler for Trang Chủ
    },
    {
      name: "Tin Tức",
      link: "/blogs",
    },
    {
      name: "Về Chúng Tôi",
      link: "/about",
    },
    {
      name: "Dịch Vụ Nha Khoa",
      link: "/service",
    },
    {
      name: "Câu Hỏi Thường Gặp",
      link: "/about#faq",
    },
  ];

  const footerContacts = [
    {
      title: "Số Điện Thoại",
      info: "(+84) 0983 880 836",
      icon: call,
    },
    {
      title: "GIỜ MỞ CỬA",
      info: "07:00 AM - 22:00 PM",
      icon: time,
    },
    {
      title: "Địa Chỉ Phòng Khám",
      info: "143 Bạch Đằng, TT. Núi Đèo, Thủy Nguyên, Hải Phòng, Vietnam",
      icon: location,
    },
  ];

  return (
    <footer className="pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-5">
            <div className="footer-logo">
              <img src={logo} alt="logo" />
            </div>
            <p>
              Nha Khoa Yến Hương là cơ sở uy tín tại Hải Phòng, chuyên về thẩm
              mỹ, phòng ngừa và phục hồi răng miệng
            </p>
            <div className="social-logo">
              <p>THEO DÕI CHÚNG TÔI</p>
              <ul>
                <li>
                  <a href="https://www.facebook.com/p/Nha-Khoa-Yến-Hương-100036004763290">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a href="/">
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a href="/">
                    <FaInstagram />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-2">
            <div className="footer-link">
              <p>LIÊN KẾT</p>
              <ul>
                {footerMenu.map((singleMenu) => (
                  <li key={singleMenu.link}>
                    <Link
                      to={singleMenu.link}
                      onClick={singleMenu.onClick || null} // Attach specific click handler
                    >
                      {singleMenu.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-5">
            <div className="footer-contact">
              <p>THÔNG TIN & LIÊN HỆ</p>
              {footerContacts.map((footerContact) => {
                return (
                  <div className="contact-list" key={footerContact.title}>
                    <div className="contact-icon">
                      <img src={footerContact.icon} alt={footerContact.title} />
                    </div>
                    <div className="contact-text">
                      <p>{footerContact.title}</p>
                      <h5>{footerContact.info}</h5>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="copy-text">
            <p>
              &copy; 2012 - {currentYear !== 2012 ? currentYear : ""} NHA KHOA
              YẾN HƯƠNG. ĐÃ ĐĂNG KÝ BẢN QUYỀN
            </p>
          </div>
          <div className="copy-links">
            <ul>
              <li>
                <Link to="/">ĐIỀU KHOẢN SỬ DỤNG</Link>
              </li>
              <li>
                <Link to="/">CHÍNH SÁCH BẢO MẬT</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="developed-by">
          <FaCode className="icon" /> DEVELOPED BY NGUYEN PHU HUNG
        </div>
      </div>
    </footer>
  );
};

export default Footer;
