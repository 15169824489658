import './App.scss';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import BlogsPage from './pages/BlogsPage';
import SingleBlog from './pages/SingleBlog/SingleBlog';
import Contactus from './pages/Contact/Contactus';
import { Routes, Route } from 'react-router-dom';
import ScrollToTopWithOffset from './components/Scrolling/ScrollToTopWithOffset'; // Import the ScrollToTop component

function App() {
  return (
    <>
      {/* ScrollToTopWithOffset ensures smooth scrolling to the top on route change */}
      <ScrollToTopWithOffset offset={0} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Services />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/blog/:url" element={<SingleBlog />} />
        <Route path="/contact" element={<Contactus />} />
      </Routes>
    </>
  );
}

export default App;
