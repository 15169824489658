import React from 'react';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import './Services.scss';
import ServicesData from './ServiceData';
import Service from '../../components/Service/Service';
import { Link } from 'react-router-dom';
import { BsFillArrowRightCircleFill } from "react-icons/bs";


const Services = () => {
    return (
        <section className='service-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-6">
                        <SectionTitle title="HÃY TỰ TIN VÀ HÀI LÒNG VỚI SỨC KHỎE RĂNG MIỆNG CỦA BẠN" subTitle="DỊCH VỤ"/>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <p className='service-title-text'>Chúng tôi mang đến các dịch vụ chăm sóc răng miệng hiện đại, từ vệ sinh răng miệng định kỳ, điều trị nha khoa tổng quát đến dịch vụ thẩm mỹ răng</p>
                    </div>
                </div>

                <div className="row">
                    {
                        ServicesData.map(singleService => <Service serviceList={singleService}/>)
                    }
                </div>
            </div>

            <div className="services-link text-center">
                <Link to='/service'>
                Xem toàn bộ danh mục dịch vụ
                    <BsFillArrowRightCircleFill/>
                </Link>
            </div>
        </section>
    );
};

export default Services;