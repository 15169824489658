import React from 'react';
import './Team.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import imgOne from '../../assets/about/team/1.jpg';
import imgTwo from '../../assets/about/team/2.jpg';
import imgThree from '../../assets/about/team/3.jpg';
import imgFour from '../../assets/about/team/4.jpg';

const Team = () => {

    const teams = [
        {
            'img': imgOne,
            'name': 'BS CK1. VŨ THỊ HƯƠNG',
            'position': 'TRƯỞNG KHOA RĂNG HÀM MẶT'
        },
        {
            'img': imgTwo,
            'name': 'BS. Chu Hồng Ngọc',
            'position': 'CHUYÊN KHOA RĂNG HÀM MẶT'
        },
        {
            'img': imgThree,
            'name': 'BS CK1. ĐỖ VĂN ĐẠI',
            'position': 'CHUYÊN KHOA RĂNG HÀM MẶT'
        },
        {
            'img': imgFour,
            'name': 'CHU THỊ HƯƠNG',
            'position': 'KỸ THUẬT VIÊN'
        },
    ];


    return (
        <section className='team-section pt-100' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <SectionTitle 
                            subTitle="GẶP GỠ ĐỘI NGŨ NHA SĨ CỦA CHÚNG TÔI"
                            title="TÌM HIỂU ĐỘI NGŨ NHA KHOA YẾN HƯƠNG"
                        />
                    </div>

                    <div className="col-lg-5">
                        <p className='pt-5'>Đội ngũ bác sĩ nha khoa của chúng tôi là những chuyên gia giàu kinh nghiệm, hiện đang công tác tại các bệnh viện hàng đầu, đảm bảo mang đến dịch vụ chăm sóc răng miệng chất lượng cao, tận tâm và an toàn tuyệt đối cho mỗi khách hàng</p>
                    </div>
                </div>

                <div className="row">
                    {
                        teams.map (team => 
                            <div className="col-lg-3 col-sm-6">
                                <div className="team-card">
                                    <div className="team-img">
                                        <img src={team.img} alt="" />
                                    </div>
                                    <h3>{team.name}</h3>
                                    <p>{team.position}</p>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </section>
    );
};

export default Team;