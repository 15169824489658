import React from 'react';
import './Safety.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';

const Safety = () => {
    return (
        <section className='safty-section pt-100 section-bg section-common pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <SectionTitle 
                    subTitle="SỰ AN TOÀN"
                    title="AN TOÀN LUÔN LÀ ƯU TIÊN HÀNG ĐẦU CỦA CHÚNG TÔI"
                    description="Chúng tôi áp dụng các tiêu chuẩn an toàn nghiêm ngặt của bộ y tế, kết hợp công nghệ hiện đại và đội ngũ bác sĩ giàu kinh nghiệm để mang đến sự yên tâm tối đa cho khách hàng"
                />

                <div className="safety-video ratio ratio-16x9">
                    <iframe src="https://www.youtube.com/embed/f4jG8BCl5s0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                </div>
            </div>
        </section>
    );
};

export default Safety;