import React from 'react';
import './PageTitle.scss';

const PageTitle = ({title,description}) => {
    return (
        <div className='page-title'>
            <div className="container">
                <h2>TIN TỨC & BÀI VIẾT</h2>
                <p>Cập nhật những tin tức và bài viết mới nhất từ chúng tôi để khám phá các mẹo và bí quyết chăm sóc sức khỏe răng miệng hiệu quả</p>
            </div>
        </div> 
    );
};

export default PageTitle;