import React from 'react';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import Navbar from '../../components/Navbar/Navbar';
import './Contactus.scss';
import ContactForm from '../../components/ContactForm/ContactForm';
import Footer from '../../sections/Footer/Footer';

const Contactus = () => {
    return (
        <>
            <section className='section-bg section-common contact-section'>
                <Navbar />
                <SectionTitle 
                    title="LIÊN HỆ VỚI CHÚNG TÔI"
                    description="Hãy liên hệ với chúng tôi để được tư vấn và giải đáp mọi thắc mắc về dịch vụ nha khoa. Chúng tôi luôn sẵn sàng lắng nghe và hỗ trợ bạn một cách tận tâm nhất"
                />
            </section>
            <section className='contact-form-area' data-aos="fade-up" data-aos-duration="2000">
                <ContactForm />
            </section>
            <Footer />
        </>
    );
};

export default Contactus;